import styled from "styled-components";
import { windowSize } from "../utils/responsive";

const SubSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 30px;
  margin-top: 124px;
  margin-bottom: 140px;
  max-width: ${windowSize.large}px;
  text-align: left;
`

export default SubSection