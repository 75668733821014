import colors from "../utils/colors";
import styled from "styled-components";
import { windowSize } from "../utils/responsive";

export const H1 = styled.h1`
  color: ${colors.black};
  font-size: 40px;
  font-weight: 900;
  margin-bottom: 10px;
  @media (max-width: ${windowSize.medium}px) {
    font-size: 32px;
  }
`
export const H2 = styled.h1`
  color: ${colors.black};
  font-size: 32px;
  font-weight: 900;
  margin-bottom: 5px;
  @media (max-width: ${windowSize.medium}px) {
    font-size: 22px;
  }
`

export const H3 = styled.h2`
  color: ${colors.black};
  font-size: 24px;
  font-weight: normal;
  margin:5px 0 5px 0;
  @media (max-width: ${windowSize.medium}px) {
    font-size: 20px;
  }
`