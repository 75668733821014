import React from "react";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import Section from "../components/Section";
import { windowSize } from "../utils/responsive";
import stockperksImg from "../images/stockperks.png";
import { H1, H2 } from "../components/TextHeadings";
import Paragraph from "../components/Paragraph";
import SubSection from "../components/SubSection";

const CareersPage = () => {

  return (
    <Layout>
      <SEO
        title="Careers"
        url={"/careers/"}
        description={
          "Stockperks is looking for you! We are always looking for amazing people with a passion in sales, account management and engineering. Please contact us at careers@stockperks.com."
        }
        image={stockperksImg}
      />
      <Section style={{padding: 0}}>
        <SubSection>
          <H1>Join Us</H1>
        </SubSection>
      </Section>
      <Section isTextCentered={true} stripe={true} style={{minHeight: 500}}>
        <div style={styles.CareerContainer}>
          <H2>
            Want to work with brilliant, energetic, fun people? Want a team that
            believes you should love what you do? Want to wake up and get excited
            about the day?
          </H2>
          <Paragraph>
            Stockperks is looking for you! We are always looking for amazing
            people with a passion in sales, account management and engineering.
            Please contact us at&nbsp;
          </Paragraph>
          <Paragraph>
            <a href="mailto:careers@stockperks.com">careers@stockperks.com</a>
          </Paragraph>
        </div>
      </Section>

    </Layout>
  );
};

const styles = {
  CareerContainer: {
    maxWidth:windowSize.large,
    padding: 25
  }
}

export default CareersPage;
