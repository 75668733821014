import styled from "styled-components";
import colors from "../utils/colors";
import { windowSize } from "../utils/responsive";

const Paragraph = styled.p`
  color: ${colors.black};
  font-size: 22px;
  font-weight: 200;
  letter-spacing: -0.02em;
  margin-top: 0;
  @media (max-width: ${windowSize.medium}px) {
    font-size: 16px;  
  }
`

export default Paragraph